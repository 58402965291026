@use '../_variables.scss';

.challenges#action-show {
  #header {
    background: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      url('images/banner-white.jpg');
    background-size: cover;
    background-position: center;
    height: 292px;
    margin-bottom: 32px;
    display: table;
    width: 100%;

    @media (max-width: variables.$mobile-width-max) {
      height: 250px;
    }

    .text-content {
      display: table-cell;
      vertical-align: middle;
      padding: 0 8px;

      h2 {
        margin: 0 auto;
        text-align: center;
        color: #4e2a84;
        font-weight: 600;
        text-shadow: 0 1px black;
        font-size: 2.5rem;
        max-width: 36rem;

        @media (max-width: variables.$tablet-width-max) {
          margin-top: 2.5rem;
        }

        @media (max-width: variables.$mobile-width-max) {
          font-size: 1.3rem;
          margin-top: initial;
        }
      }

      .subtitle {
        color: #4e2a84;
        margin: 0 auto;
        margin-top: 48px;
        padding-left: variables.$desktop-body-padding;
        padding-right: variables.$desktop-body-padding;
        text-align: center;
        font-weight: bold;

        @media (max-width: variables.$tablet-width-max) {
          margin-bottom: 1.5rem;
        }

        @media (max-width: variables.$mobile-width-max) {
          margin-bottom: initial;
          margin-top: 20px;
          font-size: 1rem;
          line-height: 1.25rem;
          padding-left: variables.$mobile-body-padding;
          padding-right: variables.$mobile-body-padding;
        }
      }
    }
  }

  .challenge {
    padding-left: variables.$desktop-body-padding;
    padding-right: variables.$desktop-body-padding;

    @media (max-width: variables.$mobile-width-max) {
      padding-left: 16px;
      padding-right: 16px;
    }

    h3 {
      @media (max-width: variables.$mobile-width-max) {
        font-size: 1.15rem;
        line-height: 1.725rem;
      }
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 32px;
      margin-bottom: 24px;

      @media (max-width: variables.$mobile-width-max) {
        font-size: 1.15rem;
      }
    }

    .mdc-button {
      @media (max-width: variables.$mobile-width-max) {
        width: 100%;
      }
    }
  }
}
