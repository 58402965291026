.admin-admins#action-index {
  th {
    font-size: 16px;
  }

  .admin-email-cell {
    padding-right: 6rem;
  }

  .mdc-data-table__cell {
    font-size: 16px;
  }
}
