@use '../_variables.scss';

.demographic_surveys#action-new,
.demographic_surveys#action-create {
  #body-content {
    padding-left: variables.$desktop-body-padding;
    padding-right: variables.$desktop-body-padding;

    @media (max-width: variables.$mobile-width-max) {
      padding-left: variables.$mobile-body-padding;
      padding-right: variables.$mobile-body-padding;
    }
  }

  .demographic-survey-page {
    p {
      margin-top: 0;
      margin-bottom: 32px;
    }

    input {
      margin-top: 0;
      margin-bottom: 16px;
    }

    button {
      margin-top: 48px;
    }

    @media (max-width: variables.$mobile-width-max) {
      p {
        margin-bottom: 16px;
      }

      input {
        margin-bottom: 8px;
      }

      button {
        margin-top: 24px;
        width: 100%;
      }
    }
  }
}
