@use '../_variables.scss';

#completion-page {
  padding-left: variables.$desktop-body-padding;
  padding-right: variables.$desktop-body-padding;
  padding-top: 15%;
  text-align: center;

  @media (max-width: variables.$mobile-width-max) {
    padding-left: variables.$mobile-body-padding;
    padding-right: variables.$mobile-body-padding;
  }
}
