.admin-ideas#action-index {
  .mdc-data-table__table {
    white-space: normal;

    form {
      display: flex;
    }

    .mdc-touch-target-wrapper {
      .mdc-button {
        min-width: 5px;
        width: 36px;
        margin-right: 0.5rem;

        .mdc-button__label {
          padding-top: 3px;
          padding-left: 1px;
        }
      }
    }

    .submitted-ideas-btn {
      i {
        scale: 1.75;
      }
    }

    .approve-idea-btn {
      background-color: #009900;
    }

    .reject-idea-btn {
      background-color: #c5000f;
    }

    td:nth-child(3) {
      width: 245px;
    }
  }
}
