@use '../_variables.scss';

.ideas#action-index {
  .intro {
    margin-top: 0;
    margin-bottom: 48px;
  }

  .please-rate {
    font-size: large;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  input,
  .rating {
    margin-bottom: 24px;
  }

  .fas {
    color: gold;
  }

  @media (max-width: variables.$mobile-width-max) {
    h1 {
      font-size: x-large;
      margin-bottom: 8px;
    }

    .intro {
      margin-bottom: 24px;
    }

    h2 {
      margin-bottom: 8px;
    }

    .idea-description {
      margin-top: 0;
    }

    .please-rate {
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .rating {
      margin-bottom: 16px;
    }

    button {
      margin-bottom: 24px;
      width: 100%;
    }
  }
}
