@use '../_variables.scss';

.homes#action-show {
  #body-content {
    max-width: unset;
    padding: 0;
  }

  #header {
    background: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      url('images/banner.jpg');
    background-size: cover;
    background-position: center;
    height: 248px;
    padding-top: 152px;
    margin-bottom: 24px;

    h2 {
      margin: 0;
      text-align: center;
      color: #4e2a84;
      font-weight: 600;
      text-shadow: 0 1px black;
      font-size: 2rem;

      @media (max-width: variables.$mobile-width-max) {
        font-size: 1.3rem;
      }
    }
  }

  .challenges {
    padding-left: variables.$desktop-body-padding;
    padding-right: variables.$desktop-body-padding;

    @media (max-width: variables.$mobile-width-max) {
      padding-left: variables.$mobile-body-padding;
      padding-right: variables.$mobile-body-padding;
    }

    h3 {
      font-weight: 600;
    }

    .challenge-card {
      display: flex;
      margin-bottom: 112px;
      box-shadow: 2px 2px 5px 2px #999;

      @media (max-width: variables.$tablet-width-max) {
        flex-direction: column;
        margin-bottom: 16px;
      }

      h4 {
        font-size: 1.5rem;
        font-weight: 400;
      }

      img {
        width: 428px;
        height: 245px;

        @media (max-width: variables.$tablet-width-max) {
          width: 100%;
          height: 100px;
          object-fit: cover;
          object-position: 0 -18px;
        }
      }

      .card-content {
        padding-left: 24px;
        padding-right: 24px;

        @media (max-width: variables.$tablet-width-max) {
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 12px;

          h4 {
            font-size: 1.25rem;
          }
        }

        .mdc-button {
          @media (max-width: variables.$tablet-width-max) {
            width: 100%;
          }
        }
      }
    }
  }
}
