@use '../_variables.scss';

.ideas#action-new,
.ideas#action-create {
  #body-content {
    background-image: url('images/logo-purple.svg');
    background-repeat: no-repeat;
    background-position: center;
    padding-left: variables.$desktop-body-padding;
    padding-right: variables.$desktop-body-padding;

    @media (max-width: variables.$mobile-width-max) {
      padding-left: variables.$mobile-body-padding;
      padding-right: variables.$mobile-body-padding;
    }

    .subhead {
      margin-top: 0;
      margin-bottom: 24px;
    }

    form {
      max-width: 100%;

      .consent-info {
        margin-top: 24px;
      }

      .form-content {
        max-width: 560px;

        .word-counter {
          font-size: 1rem;
          text-align: right;
        }

        .mdc-text-field {
          background: white;
        }

        .submit-idea-buttons {
          display: flex;

          @media (max-width: variables.$mobile-width-max) {
            display: inline-block;
          }

          .mdc-button {
            margin-top: 24px;

            @media (max-width: variables.$mobile-width-max) {
              width: 100%;
            }
          }

          .mdc-text-field + .mdc-touch-target-wrapper .mdc-button {
            margin-top: 48px;
          }

          .bottom-button {
            margin-left: 1rem;

            @media (max-width: variables.$mobile-width-max) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

.ideas#action-index {
  #body-content {
    padding-left: variables.$desktop-body-padding;
    padding-right: variables.$desktop-body-padding;

    @media (max-width: variables.$mobile-width-max) {
      padding-left: variables.$mobile-body-padding;
      padding-right: variables.$mobile-body-padding;
    }

    h1 {
      font-size: 1.75rem;
      font-weight: bold;
      line-height: normal;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 24px;
    }

    .idea {
      display: flex;
      justify-content: space-around;
      align-items: baseline;

      @media (max-width: variables.$mobile-width-max) {
        flex-direction: column;
      }

      .idea-summary {
        width: 50%;
        padding-right: 16px;

        @media (max-width: variables.$mobile-width-max) {
          margin-top: 32px;
          width: 100%;
        }
      }

      .please-rate.disabled,
      .rating.disabled .fa-star {
        color: rgba(0, 0, 0, 0.38);
      }

      .idea-rating {
        width: 50%;

        @media (max-width: variables.$mobile-width-max) {
          width: 100%;
        }
      }

      form {
        .mdc-text-field {
          margin-top: 58px;
        }
      }

      @media (max-width: variables.$mobile-width-max) {
        form {
          max-width: 100%;
        }
      }
    }

    .back-home-button {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 1rem;

      .mdc-touch-target-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        a {
          width: 25%;
        }
      }

      @media (max-width: variables.$mobile-width-max) {
        margin-top: 0;

        .mdc-touch-target-wrapper {
          a {
            width: 100%;
          }
        }
      }
    }
  }
}
